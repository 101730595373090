import React, { useState } from "react";
import { motion } from "framer-motion";
import myImage from "./../../Asset/temp/snigdha.jpg";
import "../../css/common.css";

const ProfilePage = () => {
  const [DOB, setDOB] = useState("06/21/2009");
  const [age, setAge] = useState(0);
  const getAge = (birthdate) => {
    const birthdateObj = new Date(birthdate);
    const today = new Date();
    return today.getFullYear() - birthdateObj.getFullYear();
  };
  const isAdult = (birthdate) => {
    if (getAge(birthdate) >= 18) {
      return "Adult";
    } else {
      return "Below Age";
    }
  };
  const getPM = (birthdate) => {
    if (isAdult(birthdate) === "Adult") {
      return 5000;
    } else {
      return 1000;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="d-flex">
        <div className="border bg-primary w-382px p-3">
          <img
            src={myImage}
            height="350px"
            width="350px"
            className="rounded-circle"
          ></img>
        </div>
        <div className="border bg-secondary flex-fill">
          <h1 className="border text-center pt-3">Name: Sania Alam Snigdha</h1>
          <div className="border d-flex">
            <div className="border col-sm-6 p-2">
              <p>Gender:female</p>
              <p>
                DOB: <span>{DOB}</span>
              </p>
              <p>
                Age: <span>{getAge(DOB)}</span>
              </p>
              <p>
                Adult: <span>{isAdult(DOB)}</span>
              </p>
              <p>
                Pocket Money: <span>{getPM(DOB)}</span>
              </p>
            </div>
            <div className="border col-sm-6 p-2">
              <p>Address:Mihama ku,Chiba</p>
              <div className="d-flex">
                <p className="flex-fill">Phone:0987654321</p>
                <p className="flex-fill">Email:sni@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProfilePage;
