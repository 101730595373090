let runVoice = [[
    "Batsman $$$$$ defends it solidly, no run scored.",
    "A watchful leave outside off, no run taken by $$$$$.",
    "A dot ball as $$$$$ plays it back to the bowler, no run.",
    "$$$$$ shoulders arms, and it goes through to the keeper, no run.",
    "A good-length delivery, and $$$$$ plays it cautiously, no run added.",
    "Batsman $$$$$ lets it go outside off stump, no run taken.",
    "$$$$$ defends with a straight bat, no run scored.",
    "A solid block by $$$$$, no run from that delivery.",
    "$$$$$ gets right behind it and plays it to mid-on, no run taken.",
    "A forward defensive shot by $$$$$, no run scored.",
    "$$$$$ leaves it alone outside off, no run taken.",
    "A dot ball as $$$$$ defends it back to the bowler, no run.",
    "$$$$$ gets in line and plays it to the off-side, no run taken.",
    "A cautious play by $$$$$, no run scored.",
    "$$$$$ plays it watchfully, no run from that delivery.",
    "A dot ball as $$$$$ defends it to mid-off, no run.",
    "$$$$$ plays it with soft hands to the leg side, no run taken.",
    "A leave outside off by $$$$$, no run scored.",
    "$$$$$ gets right behind it and blocks it, no run taken.",
    "A forward defense by $$$$$, no run added."
],
[
    "Batsman $$$$$ pushes it gently and takes a quick single!",
    "A soft touch into the leg side, and $$$$$ sprints for a single!",
    "A tap and run, and $$$$$ is off for a single!",
    "Batsman $$$$$ guides it to the off-side and steals a single!",
    "A nudge to mid-wicket, and they pick up a single, well-run!",
    "A flick to the leg side, and $$$$$ is swift between the wickets, one run taken!",
    "Batsman $$$$$ plays it late and gets a single down to third man!",
    "A quick call, and they complete a single, good running!",
    "A push to cover, and $$$$$ hustles for a single!",
    "Batsman $$$$$ tucks it into the leg side, and they grab a single!",
    "A gentle drive into the gap, and $$$$$ is off for a single!",
    "Batsman $$$$$ finds the gap, and they're taking on the fielder for a single!",
    "A drop-and-run, and $$$$$ is quick off the mark, one run added!",
    "Batsman $$$$$ plays it straight, and they're off for a single!",
    "A quick single, and $$$$$ turns it into one, well-judged!",
    "Batsman $$$$$ works it to mid-on and gets a single!",
    "A nudge to the leg side, and they scamper for a single!",
    "Batsman $$$$$ drives it to the covers, and they take a single!",
    "A push to mid-off, and $$$$$ steals a single!",
    "Batsman $$$$$ drops it at his feet and gets a single!",
],
[
    "A quick single taken, and they push for an extra run, making it TWO runs!",
    "Batsman $$$$$ guides it into the gap and sprints for a couple, TWO runs taken!",
    "A well-placed shot, they're running hard between the wickets, and it's TWO runs!",
    "A nudge to the leg side, and they come back for a second run, TWO runs scored!",
    "Batsman $$$$$ flicks it into the gap, and they complete a comfortable TWO runs!",
    "A push into the off-side, and they hustle for a quick double, TWO runs added!",
    "A drive to the fielder, but they're stealing a second run, it's TWO runs!",
    "Batsman $$$$$ plays it late, and they're coming back for the second run, TWO runs taken!",
    "A quick call by $$$$$, and they're turning a single into a double, TWO runs on the board!",
    "A push to the off-side, and $$$$$ is quick between the wickets, TWO runs scored!",
    "A well-timed shot into the gap, they're running hard, and it's TWO runs!",
    "Batsman $$$$$ finds the hole in the field, and they complete a brace, TWO runs taken!",
    "A flick off the pads, and they're back for the second run, TWO runs earned!",
    "A glance to the leg side, and they come back for the second run, TWO runs to the total!",
    "Batsman $$$$$ nudges it into the gap, and they're pushing for the second run, TWO runs taken!",
    "A quick single, and they're turning it into a double, TWO runs scored!",
    "A drive into the covers, and they're sprinting for the second run, TWO runs added!",
    "Batsman $$$$$ uses his wrists to place it, and they complete a double, TWO runs on the board!",
    "A quick call, and they're taking on the fielder's arm for the second run, TWO runs earned!",
    "A well-placed shot, and they're running hard, it's TWO runs!"
],
[
    "A quick single taken, and they push for two more, making it THREE runs!",
    "Batsman $$$$$ guides it into the gap and sprints for a couple, THREE runs taken!",
    "A well-placed shot, they're running hard between the wickets, and it's THREE runs!",
    "A nudge to the leg side, and they come back for two, THREE runs scored!",
    "Batsman $$$$$ flicks it into the gap, and they complete a comfortable THREE runs!",
    "A push into the off-side, and they hustle for a quick triple, THREE runs added!",
    "A drive to the fielder, but they're stealing a third run, it's THREE runs!",
    "Batsman $$$$$ plays it late, and they're coming back for the third run, THREE runs taken!",
    "A quick call by $$$$$, and they're turning a single into a triple, THREE runs on the board!",
    "A push to the off-side, and $$$$$ is quick between the wickets, THREE runs scored!",
    "A well-timed shot into the gap, they're running hard, and it's THREE runs!",
    "Batsman $$$$$ finds the hole in the field, and they complete a trio, THREE runs taken!",
    "A flick off the pads, and they're back for the third run, THREE runs earned!",
    "A glance to the leg side, and they come back for the third run, THREE runs to the total!",
    "Batsman $$$$$ nudges it into the gap, and they're pushing for the third run, THREE runs taken!",
    "A quick single, and they're turning it into a triple, THREE runs scored!",
    "A drive into the covers, and they're sprinting for the third run, THREE runs added!",
    "Batsman $$$$$ uses his wrists to place it, and they complete a triple, THREE runs on the board!",
    "A quick call, and they're taking on the fielder's arm for the third run, THREE runs earned!",
    "A well-placed shot, and they're running hard, it's THREE runs!"
],
[
    "A well-timed shot, and it races to the boundary for FOUR runs!",
    "Batsman $$$$$ plays it late and guides it through the gap for FOUR runs!",
    "A powerful pull shot, and it beats the fielder for FOUR runs!",
    "A lofted drive, and $$$$$ finds the gap for FOUR runs!",
    "A flick of the wrists, and it's through the leg-side for FOUR runs!",
    "The ball races to the boundary, and $$$$$ gets FOUR runs!",
    "Batsman $$$$$ stands tall and punches it through the covers for FOUR runs!",
    "A cleverly placed shot, and it's running away for FOUR runs!",
    "A well-placed cut shot, and $$$$$ earns FOUR runs!",
    "The ball races to the boundary, and $$$$$ collects FOUR runs!",
    "A deft touch by $$$$$, and it's racing to the fence for FOUR runs!",
    "A powerful drive, and $$$$$ pierces the field for FOUR runs!",
    "Batsman $$$$$ leans into the shot and drives it through mid-off for FOUR runs!",
    "A flick off the pads, and $$$$$ gets FOUR runs to his name!",
    "A late cut, and it's perfectly placed for FOUR runs!",
    "Batsman $$$$$ finds the gap through the off-side, and it's FOUR runs!",
    "A mistimed shot, but $$$$$ gets FOUR runs nevertheless!",
    "The ball beats the fielder, and $$$$$ gets FOUR runs to his name!",
    "A well-executed pull shot, and it races to the boundary for FOUR runs!",
    "A straight drive, and $$$$$ collects FOUR runs with ease!"
],
[
    "A massive hit over long-on, the ball is out of here! SIX runs!",
    "Batsman $$$$$ dances down the track and launches it into the stands! SIX runs!",
    "A textbook lofted drive by $$$$$, it's a maximum! SIX runs!",
    "The bowler delivers a full toss, and $$$$$ punishes it for SIX runs!",
    "A slog sweep over deep mid-wicket, it's a big one! SIX runs!",
    "Batsman $$$$$ goes downtown, clears the boundary with ease! SIX runs!",
    "Aerial route taken by $$$$$, and it's gone all the way! SIX runs!",
    "A powerful pull shot, the ball sails into the crowd! SIX runs!",
    "Batsman $$$$$ charges down and smacks it over long-off for SIX runs!",
    "A well-timed flick by $$$$$, it's a maximum! SIX runs!",
    "A classic cover drive by $$$$$, it's a maximum! SIX runs!",
    "The ball disappears into the night sky! SIX runs!",
    "A monstrous hit, and $$$$$ showcases his power! SIX runs!",
    "Batsman $$$$$ showcases his strength with a big shot! SIX runs!",
    "A sweetly timed shot by $$$$$, and it's cleared the boundary! SIX runs!",
    "The crowd erupts as $$$$$'s shot sails over the fence! SIX runs!",
    "Batsman $$$$$ goes big, and it's sailing into the crowd! SIX runs!",
    "A majestic strike by $$$$$, and it's a maximum! SIX runs!",
    "A colossal hit, and $$$$$ sends the ball into the stands! SIX runs!",
    "A towering six, and $$$$$ leaves the bowler stunned! SIX runs!"
],
]
export { runVoice };