let countries =
{
  "India": {
    "id": 1,
    "name": "India",
    "current_ranking": 1,
    "image": "./asset/img/icon/flag/india.png"
  },
  "Australia": {
    "id": 2,
    "name": "Australia",
    "current_ranking": 2,
    "image": "./asset/img/icon/flag/australia.png"
  },
  "England": {
    "id": 3,
    "name": "England",
    "current_ranking": 3,
    "image": "./asset/img/icon/flag/england.png"
  },
  "New Zealand": {
    "id": 4,
    "name": "New Zealand",
    "current_ranking": 4,
    "image": "./asset/img/icon/flag/new-zealand.png"
  },
  "Pakistan": {
    "id": 5,
    "name": "Pakistan",
    "current_ranking": 5,
    "image": "./asset/img/icon/flag/pakistan.png"
  },
  "South Africa": {
    "id": 6,
    "name": "South Africa",
    "current_ranking": 6,
    "image": "./asset/img/icon/flag/south-africa.png"
  },
  "West Indies": {
    "id": 7,
    "name": "West Indies",
    "current_ranking": 7,
    "image": "./asset/img/icon/flag/west-indies.png"
  },
  "Sri Lanka": {
    "id": 8,
    "name": "Sri Lanka",
    "current_ranking": 8,
    "image": "./asset/img/icon/flag/sri-lanka.png"
  },
  "Bangladesh": {
    "id": 9,
    "name": "Bangladesh",
    "current_ranking": 9,
    "image": "./asset/img/icon/flag/bangladesh.png"
  },
  "Afghanistan": {
    "id": 10,
    "name": "Afghanistan",
    "current_ranking": 10,
    "image": "./asset/img/icon/flag/afgan.png"
  },
  "Ireland": {
    "id": 11,
    "name": "Ireland",
    "current_ranking": 11,
    "image": "./asset/img/icon/flag/ireland.png"
  },
  "Zimbabwe": {
    "id": 12,
    "name": "Zimbabwe",
    "current_ranking": 12,
    "image": "./asset/img/icon/flag/zimbabwe.png"
  },
  "Netherlands": {
    "id": 13,
    "name": "Netherlands",
    "current_ranking": 13,
    "image": "./asset/img/icon/flag/netherlands.png"
  },
  "Scotland": {
    "id": 14,
    "name": "Scotland",
    "current_ranking": 14,
    "image": "./asset/img/icon/flag/scotland.png"
  },
  "Nepal": {
    "id": 15,
    "name": "Nepal",
    "current_ranking": 15,
    "image": "./asset/img/icon/flag/nepal.png"
  },
  "UAE": {
    "id": 16,
    "name": "UAE",
    "current_ranking": 16,
    "image": "./asset/img/icon/flag/uae.png"
  },
  "Oman": {
    "id": 17,
    "name": "Oman",
    "current_ranking": 17,
    "image": "./asset/img/icon/flag/oman.png"
  },
  "Namibia": {
    "id": 18,
    "name": "Namibia",
    "current_ranking": 18,
    "image": "./asset/img/icon/flag/namibia.png"
  },
  "Canada": {
    "id": 19,
    "name": "Canada",
    "current_ranking": 19,
    "image": "./asset/img/icon/flag/canada.png"
  },
  "Kenya": {
    "id": 20,
    "name": "Kenya",
    "current_ranking": 20,
    "image": "./asset/img/icon/flag/kenya.png"
  }
}
export { countries };
