// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCMxExJjBfOSDOCdM7zgGsj3QrIMVh9aic",
    authDomain: "snigtus-bd.firebaseapp.com",
    projectId: "snigtus-bd",
    storageBucket: "snigtus-bd.appspot.com",
    messagingSenderId: "319844164101",
    appId: "1:319844164101:web:733bf5c170b2373177b26c",
    measurementId: "G-N02LBBX5JK"
};

// Initialize Firebase
export default firebaseConfig;