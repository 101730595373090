let matchTypeList =
{
    "micro": {
        "over": 1,
        'innings': 1
    },
    "mini": {
        "over": 5,
        'innings': 1
    },
    "10aSide": {
        "over": 10,
        'innings': 1
    },
    "t20": {
        "over": 20,
        'innings': 1
    },
    "oneDay": {
        "over": 50,
        'innings': 1
    },
}
export { matchTypeList };
