let registrationColumn = {
    None: 0,
    Name: 1,
    Email: 2,
    Phone: 3,
    Address: 4,
    Password: 5,
    DOB: 6,
    Gender: 7,
}

export { registrationColumn };