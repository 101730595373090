let playerListForNation =
{
    Afghanistan: [
        {
            id: 1,
            name: "Hazratullah Zazai",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 60,
            battingAggresion: 85,
            isWicketKeeper: false,
            spinAbility: 15,
            paceAbility: 50,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "Rahmat Shah",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 75,
            battingAggresion: 75,
            isWicketKeeper: false,
            spinAbility: 55,
            paceAbility: 15,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Asghar Afghan",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 70,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 45,
            paceAbility: 20,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "Mohammad Nabi",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 80,
            battingAggresion: 65,
            isWicketKeeper: false,
            spinAbility: 80,
            paceAbility: 35,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Najibullah Zadran",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggresion: 75,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Mohammad Shahzad",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggresion: 80,
            isWicketKeeper: true,
            spinAbility: 25,
            paceAbility: 35,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "Rashid Khan",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 90,
            battingAggresion: 50,
            isWicketKeeper: false,
            spinAbility: 95,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "Gulbadin Naib",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 60,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Dawlat Zadran",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 55,
            battingAggresion: 45,
            isWicketKeeper: false,
            spinAbility: 20,
            paceAbility: 80,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Mujeeb Ur Rahman",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 85,
            battingAggresion: 55,
            isWicketKeeper: false,
            spinAbility: 90,
            paceAbility: 45,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Hamid Hassan",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 60,
            battingAggresion: 45,
            isWicketKeeper: false,
            spinAbility: 20,
            paceAbility: 75,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        // You can continue updating the other players with their respective abilities
    ],
    Australia: [
        {
            id: 1,
            name: "Aaron Finch",
            abilityToPlayPaceBall: 85,
            abilityToPlaySpinBall: 70,
            battingAggresion: 90,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 20,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "David Warner",
            abilityToPlayPaceBall: 90,
            abilityToPlaySpinBall: 75,
            battingAggresion: 88,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Steve Smith",
            abilityToPlayPaceBall: 88,
            abilityToPlaySpinBall: 85,
            battingAggresion: 85,
            isWicketKeeper: false,
            spinAbility: 45,
            paceAbility: 35,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "Glenn Maxwell",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 75,
            battingAggresion: 90,
            isWicketKeeper: false,
            spinAbility: 50,
            paceAbility: 45,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Marcus Stoinis",
            abilityToPlayPaceBall: 85,
            abilityToPlaySpinBall: 70,
            battingAggresion: 85,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 50,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Alex Carey",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggresion: 75,
            isWicketKeeper: true,
            spinAbility: 20,
            paceAbility: 10,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "Pat Cummins",
            abilityToPlayPaceBall: 60,
            abilityToPlaySpinBall: 40,
            battingAggresion: 35,
            isWicketKeeper: false,
            spinAbility: 15,
            paceAbility: 90,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "Mitchell Starc",
            abilityToPlayPaceBall: 50,
            abilityToPlaySpinBall: 35,
            battingAggresion: 25,
            isWicketKeeper: false,
            spinAbility: 10,
            paceAbility: 95,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Josh Hazlewood",
            abilityToPlayPaceBall: 50,
            abilityToPlaySpinBall: 35,
            battingAggresion: 30,
            isWicketKeeper: false,
            spinAbility: 15,
            paceAbility: 90,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Adam Zampa",
            abilityToPlayPaceBall: 45,
            abilityToPlaySpinBall: 80,
            battingAggresion: 40,
            isWicketKeeper: false,
            spinAbility: 85,
            paceAbility: 20,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Kane Richardson",
            abilityToPlayPaceBall: 55,
            abilityToPlaySpinBall: 40,
            battingAggresion: 35,
            isWicketKeeper: false,
            spinAbility: 10,
            paceAbility: 80,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
    ],
    Bangladesh: [
        {
            id: 1,
            name: "Tamim Iqbal",
            abilityToPlayPaceBall: 85,
            abilityToPlaySpinBall: 75,
            battingAggresion: 80,
            isWicketKeeper: false,
            spinAbility: 25,
            paceAbility: 10,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "Soumya Sarkar",
            abilityToPlayPaceBall: 82,
            abilityToPlaySpinBall: 76,
            battingAggresion: 78,
            isWicketKeeper: false,
            spinAbility: 20,
            paceAbility: 15,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Touhid Hridoy",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 70,
            battingAggresion: 75,
            isWicketKeeper: false,
            spinAbility: 15,
            paceAbility: 10,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "Najmul Hossain Santo",
            abilityToPlayPaceBall: 78,
            abilityToPlaySpinBall: 72,
            battingAggresion: 76,
            isWicketKeeper: false,
            spinAbility: 18,
            paceAbility: 12,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Sakib Al Hasan",
            abilityToPlayPaceBall: 88,
            abilityToPlaySpinBall: 90,
            battingAggresion: 85,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Mushfiqur Rahim",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 82,
            battingAggresion: 78,
            isWicketKeeper: false,
            spinAbility: 25,
            paceAbility: 10,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "Liton Das",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 80,
            battingAggresion: 78,
            isWicketKeeper: true,
            spinAbility: 20,
            paceAbility: 10,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "Mehidy Hasan Miraz",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 85,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 15,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Mustafizur Rahman",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 70,
            battingAggresion: 60,
            isWicketKeeper: false,
            spinAbility: 10,
            paceAbility: 50,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Taskin Ahmed",
            abilityToPlayPaceBall: 85,
            abilityToPlaySpinBall: 70,
            battingAggresion: 65,
            isWicketKeeper: false,
            spinAbility: 10,
            paceAbility: 55,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Shoriful Islam",
            abilityToPlayPaceBall: 78,
            abilityToPlaySpinBall: 75,
            battingAggresion: 72,
            isWicketKeeper: false,
            spinAbility: 15,
            paceAbility: 45,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        // You can continue updating the other players with their respective abilities
    ],
    Canada: [
        {
            id: 1,
            name: "Navneet Dhaliwal",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggresion: 75,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "Ravinderpal Singh",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 55,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Rizwan Cheema",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggresion: 80,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "Nitish Kumar",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggresion: 75,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Dillon Heyliger",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 55,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Srimantha Wijeyeratne",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggresion: 80,
            isWicketKeeper: true,
            spinAbility: 30,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "Saad Bin Zafar",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggresion: 75,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "Hamza Tariq",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 55,
            battingAggresion: 70,
            isWicketKeeper: true,
            spinAbility: 35,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Junaid Siddiqui",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggresion: 75,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Nikil Dutta",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 55,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Jeremy Gordon",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggresion: 80,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
    ],
    England: [
        {
            id: 1,
            name: "Jason Roy",
            abilityToPlayPaceBall: 85,
            abilityToPlaySpinBall: 70,
            battingAggresion: 90,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 20,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "Jonny Bairstow",
            abilityToPlayPaceBall: 88,
            abilityToPlaySpinBall: 75,
            battingAggresion: 88,
            isWicketKeeper: true,
            spinAbility: 40,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Joe Root",
            abilityToPlayPaceBall: 85,
            abilityToPlaySpinBall: 80,
            battingAggresion: 85,
            isWicketKeeper: false,
            spinAbility: 45,
            paceAbility: 35,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "Eoin Morgan",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 75,
            battingAggresion: 90,
            isWicketKeeper: false,
            spinAbility: 50,
            paceAbility: 45,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Jos Buttler",
            abilityToPlayPaceBall: 82,
            abilityToPlaySpinBall: 75,
            battingAggresion: 85,
            isWicketKeeper: true,
            spinAbility: 40,
            paceAbility: 50,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Ben Stokes",
            abilityToPlayPaceBall: 85,
            abilityToPlaySpinBall: 80,
            battingAggresion: 90,
            isWicketKeeper: false,
            spinAbility: 45,
            paceAbility: 55,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "Chris Woakes",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 60,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 80,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "Jofra Archer",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 40,
            battingAggresion: 45,
            isWicketKeeper: false,
            spinAbility: 25,
            paceAbility: 90,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Adil Rashid",
            abilityToPlayPaceBall: 60,
            abilityToPlaySpinBall: 85,
            battingAggresion: 60,
            isWicketKeeper: false,
            spinAbility: 85,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Mark Wood",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 45,
            battingAggresion: 55,
            isWicketKeeper: false,
            spinAbility: 20,
            paceAbility: 95,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Stuart Broad",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 35,
            battingAggresion: 40,
            isWicketKeeper: false,
            spinAbility: 15,
            paceAbility: 90,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
    ],
    India: [
        {
            id: 1,
            name: "Shubman Gill",
            abilityToPlayPaceBall: 85,
            abilityToPlaySpinBall: 78,
            battingAggresion: 85,
            isWicketKeeper: false,
            spinAbility: 10,
            paceAbility: 8,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "Rohit Sharma",
            abilityToPlayPaceBall: 88,
            abilityToPlaySpinBall: 80,
            battingAggresion: 88,
            isWicketKeeper: false,
            spinAbility: 15,
            paceAbility: 10,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Virat Kohli",
            abilityToPlayPaceBall: 90,
            abilityToPlaySpinBall: 85,
            battingAggresion: 92,
            isWicketKeeper: false,
            spinAbility: 20,
            paceAbility: 15,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "KL Rahul",
            abilityToPlayPaceBall: 87,
            abilityToPlaySpinBall: 82,
            battingAggresion: 86,
            isWicketKeeper: true,
            spinAbility: 10,
            paceAbility: 5,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Rishabh Pant",
            abilityToPlayPaceBall: 84,
            abilityToPlaySpinBall: 80,
            battingAggresion: 88,
            isWicketKeeper: true,
            spinAbility: 15,
            paceAbility: 10,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Hardik Pandya",
            abilityToPlayPaceBall: 85,
            abilityToPlaySpinBall: 75,
            battingAggresion: 85,
            isWicketKeeper: false,
            spinAbility: 10,
            paceAbility: 50,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "Ravindra Jadeja",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 85,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 50,
            paceAbility: 20,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "Bhuvneshwar Kumar",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 55,
            battingAggresion: 60,
            isWicketKeeper: false,
            spinAbility: 10,
            paceAbility: 90,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Jasprit Bumrah",
            abilityToPlayPaceBall: 50,
            abilityToPlaySpinBall: 40,
            battingAggresion: 30,
            isWicketKeeper: false,
            spinAbility: 10,
            paceAbility: 99,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Yuzvendra Chahal",
            abilityToPlayPaceBall: 45,
            abilityToPlaySpinBall: 85,
            battingAggresion: 40,
            isWicketKeeper: false,
            spinAbility: 85,
            paceAbility: 10,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Shardul Thakur",
            abilityToPlayPaceBall: 60,
            abilityToPlaySpinBall: 50,
            battingAggresion: 45,
            isWicketKeeper: false,
            spinAbility: 10,
            paceAbility: 75,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        // You can continue updating the other players with their respective abilities
    ],
    Ireland: [
        {
            id: 1,
            name: "Paul Stirling",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 70,
            battingAggresion: 85,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "William Porterfield",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 65,
            battingAggresion: 75,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Andy Balbirnie",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 70,
            battingAggresion: 80,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "Kevin O'Brien",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 60,
            battingAggresion: 85,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 35,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Gary Wilson",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 65,
            battingAggresion: 75,
            isWicketKeeper: true,
            spinAbility: 35,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Mark Adair",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 55,
            battingAggresion: 65,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 50,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "Simi Singh",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 70,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 35,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "George Dockrell",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 75,
            battingAggresion: 65,
            isWicketKeeper: false,
            spinAbility: 75,
            paceAbility: 20,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Boyd Rankin",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 45,
            battingAggresion: 55,
            isWicketKeeper: false,
            spinAbility: 20,
            paceAbility: 85,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Barry McCarthy",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 40,
            battingAggresion: 45,
            isWicketKeeper: false,
            spinAbility: 25,
            paceAbility: 80,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Craig Young",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 35,
            battingAggresion: 40,
            isWicketKeeper: false,
            spinAbility: 15,
            paceAbility: 85,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
    ],
    Kenya: [
        {
            id: 1,
            name: "Collins Obuya",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 75,
            battingAggresion: 65,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "Steve Tikolo",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 80,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 25,
            paceAbility: 20,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Maurice Odumbe",
            abilityToPlayPaceBall: 68,
            abilityToPlaySpinBall: 72,
            battingAggresion: 66,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "Thomas Odoyo",
            abilityToPlayPaceBall: 72,
            abilityToPlaySpinBall: 70,
            battingAggresion: 68,
            isWicketKeeper: false,
            spinAbility: 20,
            paceAbility: 35,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Steve Ouma",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 70,
            battingAggresion: 62,
            isWicketKeeper: true,
            spinAbility: 20,
            paceAbility: 10,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Ravi Shah",
            abilityToPlayPaceBall: 68,
            abilityToPlaySpinBall: 72,
            battingAggresion: 70,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 20,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "Morris Ouma",
            abilityToPlayPaceBall: 63,
            abilityToPlaySpinBall: 68,
            battingAggresion: 60,
            isWicketKeeper: false,
            spinAbility: 25,
            paceAbility: 15,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "Collins Omondi",
            abilityToPlayPaceBall: 60,
            abilityToPlaySpinBall: 65,
            battingAggresion: 58,
            isWicketKeeper: false,
            spinAbility: 20,
            paceAbility: 18,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Peter Ongondo",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 60,
            battingAggresion: 55,
            isWicketKeeper: false,
            spinAbility: 15,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Hiren Varaiya",
            abilityToPlayPaceBall: 60,
            abilityToPlaySpinBall: 70,
            battingAggresion: 65,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 20,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Kennedy Otieno",
            abilityToPlayPaceBall: 62,
            abilityToPlaySpinBall: 65,
            battingAggresion: 60,
            isWicketKeeper: true,
            spinAbility: 20,
            paceAbility: 15,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        // You can continue updating the other players with their respective abilities
    ],
    Namibia: [
        {
            id: 1,
            name: "Gerhard Erasmus",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggression: 80,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "Stephen Baard",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggression: 75,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Craig Williams",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 55,
            battingAggression: 70,
            isWicketKeeper: false,
            spinAbility: 25,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "Niko Davin",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggression: 80,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Christi Viljoen",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggression: 75,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Zane Green",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 55,
            battingAggression: 70,
            isWicketKeeper: true,
            spinAbility: 25,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "JJ Smit",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggression: 80,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "Jan Frylinck",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggression: 75,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Tangeni Lungameni",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 55,
            battingAggression: 70,
            isWicketKeeper: false,
            spinAbility: 25,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Karl Birkenstock",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggression: 80,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Ruben Trumpelmann",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggression: 75,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
    ],
    Nepal: [
        {
            id: 1,
            name: "Paras Khadka",
            abilityToPlayPaceBall: 80,
            abilityToPlaySpinBall: 70,
            battingAggression: 85,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 2,
            name: "Gyanendra Malla",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggression: 75,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 1,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 3,
            name: "Dipendra Singh Airee",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 70,
            battingAggression: 80,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 4,
            name: "Sompal Kami",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 60,
            battingAggression: 85,
            isWicketKeeper: false,
            spinAbility: 40,
            paceAbility: 35,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 5,
            name: "Sandeep Lamichhane",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 75,
            battingAggression: 75,
            isWicketKeeper: false,
            spinAbility: 75,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 6,
            name: "Aarif Sheikh",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 55,
            battingAggression: 70,
            isWicketKeeper: false,
            spinAbility: 30,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 7,
            name: "Rohit Kumar Paudel",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 70,
            battingAggression: 70,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 8,
            name: "Karan KC",
            abilityToPlayPaceBall: 65,
            abilityToPlaySpinBall: 75,
            battingAggression: 65,
            isWicketKeeper: false,
            spinAbility: 75,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 9,
            name: "Binod Bhandari",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 45,
            battingAggression: 60,
            isWicketKeeper: true,
            spinAbility: 30,
            paceAbility: 25,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 10,
            name: "Sushan Bhari",
            abilityToPlayPaceBall: 70,
            abilityToPlaySpinBall: 60,
            battingAggression: 70,
            isWicketKeeper: false,
            spinAbility: 25,
            paceAbility: 40,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
        {
            id: 11,
            name: "Kushal Bhurtel",
            abilityToPlayPaceBall: 75,
            abilityToPlaySpinBall: 65,
            battingAggression: 80,
            isWicketKeeper: false,
            spinAbility: 35,
            paceAbility: 30,
            currentMatch: {
                run: 0,
                ball: 0,
                isBatting: 0,
                b_run: 0,
                b_ball: 0,
                b_wkt: 0,
            },
        },
    ],








    // Now, the array contains updated player abilities based on fictional data

};
export { playerListForNation };