// Login.js
import React, { useState } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebaseConfig from "../../Config/firebaseConfig";
// import { getAnalytics } from "firebase/analytics";

import "../../css/common.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { motion } from "framer-motion";

const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = firebase.firestore();

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const performLoginByEnterPress = (e) => {
    console.log("===", e.keyCode);
    if (e.keyCode === 13) {
      return handleLoginWithEmail();
    }
  };

  const handleLoginWithEmail = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      navigate("/home");
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleLoginWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      // You can access user information from result.user
      console.log(result.user);
      navigate("/profile");
    } catch (error) {
      console.error(error.message);
    }
  };

  const joinAsNew = () => {
    navigate("/new");
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="d-flex justify-content-center align-items-center vh-100 vw-100 bg-aliceblue"
    >
      <div className="box p-5 border border-rounded bg-glass">
        <h2>
          Login To <span className="font-volcano">SnigTus</span>
        </h2>
        <div className="form-floating mt-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            id="email"
            placeholder="Enter email"
            name="email"
          />
          <label htmlFor="email">Email</label>
        </div>
        <div className="form-floating mt-3">
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="form-control"
            id="password"
            placeholder="Enter password"
            name="password"
          />
          <label htmlFor="password">Password</label>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button
            onClick={handleLoginWithEmail}
            onKeyDown={performLoginByEnterPress}
            className="btn btn-primary px-4 me-2"
          >
            Login
          </button>
          <button
            onClick={handleLoginWithGoogle}
            className="btn btn-danger px-4"
          >
            Login with Gmail
          </button>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button onClick={joinAsNew} className="btn btn-secondary mb-2 w-100">
            Join As New Member
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default LoginPage;
