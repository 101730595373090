let stadiums =
{
  "Afghanistan": [
    {
      "name": "Shaheed Mohtarma Benazir Bhutto International Cricket Stadium",
      "capacity": 25000,
      "location": "Kabul",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2008",
      "matchesHosted": {
        "ODIs": 20,
        "T20Is": 11
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Kandahar International Cricket Stadium",
      "capacity": 15000,
      "location": "Kandahar",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "2011",
      "matchesHosted": {
        "ODIs": 7,
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Herat International Cricket Stadium",
      "capacity": 18000,
      "location": "Herat",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2015",
      "matchesHosted": {
        "ODIs": 8,
        "T20Is": 5
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Nangarhar International Cricket Stadium",
      "capacity": 12000,
      "location": "Nangarhar",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2017",
      "matchesHosted": {
        "ODIs": 6,
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Balkh Cricket Stadium",
      "capacity": 10000,
      "location": "Balkh",
      "facilities": ["Media Center", "Indoor Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2019",
      "matchesHosted": {
        "ODIs": 5,
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Khost Cricket Stadium",
      "capacity": 8000,
      "location": "Khost",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "2016",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Paktia Cricket Stadium",
      "capacity": 10000,
      "location": "Paktia",
      "facilities": ["Media Center", "Practice Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2018",
      "matchesHosted": {
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Bamyan Cricket Stadium",
      "capacity": 5000,
      "location": "Bamyan",
      "facilities": ["Media Center", "Practice Pitches", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2020",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Zabul Cricket Stadium",
      "capacity": 7000,
      "location": "Zabul",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2019",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Logar Cricket Stadium",
      "capacity": 6000,
      "location": "Logar",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2021",
      "matchesHosted": {
        "T20Is": 1
      }
    }
  ],
  "Australia": [
    {
      "name": "Melbourne Cricket Ground (MCG)",
      "capacity": 100024,
      "location": "Melbourne, Victoria",
      "facilities": ["Great Southern Stand", "Ponsford Stand", "Media Center"],
      "pitchType": "Drop-in",
      "inaugurated": "1853",
      "matchesHosted": {
        "Test Matches": 117,
        "ODIs": 169,
        "T20Is": 20
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Sydney Cricket Ground (SCG)",
      "capacity": 48601,
      "location": "Sydney, New South Wales",
      "facilities": ["Bradman Stand", "Media Center", "Indoor Nets"],
      "pitchType": "Grass",
      "inaugurated": "1848",
      "matchesHosted": {
        "Test Matches": 111,
        "ODIs": 156,
        "T20Is": 21
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "The Gabba",
      "capacity": 42000,
      "location": "Brisbane, Queensland",
      "facilities": ["Ian Healy Stand", "Media Center", "Practice Pitches"],
      "pitchType": "Grass",
      "inaugurated": "1895",
      "matchesHosted": {
        "Test Matches": 65,
        "ODIs": 82,
        "T20Is": 12
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Adelaide Oval",
      "capacity": 53000,
      "location": "Adelaide, South Australia",
      "facilities": ["Bradman Pavilion", "Media Center", "Indoor Training"],
      "pitchType": "Grass",
      "inaugurated": "1871",
      "matchesHosted": {
        "Test Matches": 82,
        "ODIs": 91,
        "T20Is": 11
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Perth Stadium",
      "capacity": 60000,
      "location": "Perth, Western Australia",
      "facilities": ["Lillee-Marsh Stand", "Media Center", "Fitness Center"],
      "pitchType": "Drop-in",
      "inaugurated": "2018",
      "matchesHosted": {
        "Test Matches": 7,
        "ODIs": 18,
        "T20Is": 6
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Bellerive Oval",
      "capacity": 16500,
      "location": "Hobart, Tasmania",
      "facilities": ["Members Stand", "Media Center", "Nets Area"],
      "pitchType": "Grass",
      "inaugurated": "1914",
      "matchesHosted": {
        "Test Matches": 33,
        "ODIs": 33,
        "T20Is": 10
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Manuka Oval",
      "capacity": 12500,
      "location": "Canberra, Australian Capital Territory",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1929",
      "matchesHosted": {
        "ODIs": 13,
        "T20Is": 8
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Traeger Park",
      "capacity": 7000,
      "location": "Alice Springs, Northern Territory",
      "facilities": ["Media Center", "Training Facilities", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2008",
      "matchesHosted": {
        "ODIs": 3,
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Junction Oval",
      "capacity": 7000,
      "location": "Melbourne, Victoria",
      "facilities": ["Pavilion", "Media Center", "Practice Pitches"],
      "pitchType": "Grass",
      "inaugurated": "1856",
      "matchesHosted": {
        "ODIs": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Sydney Showground Stadium",
      "capacity": 21000,
      "location": "Sydney, New South Wales",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1998",
      "matchesHosted": {
        "ODIs": 5,
        "T20Is": 4
      }
    }
  ],
  "Bangladesh": [
    {
      "name": "Sher-e-Bangla National Cricket Stadium",
      "capacity": 26000,
      "location": "Mirpur, Dhaka",
      "facilities": ["VIP Pavilion", "Media Center", "Practice Pitches"],
      "pitchType": "Grass",
      "inaugurated": "2006",
      "matchesHosted": {
        "Test Matches": 14,
        "ODIs": 84,
        "T20Is": 36
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Zahur Ahmed Chowdhury Stadium",
      "capacity": 22000,
      "location": "Chattogram",
      "facilities": ["Media Center", "Indoor Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2004",
      "matchesHosted": {
        "Test Matches": 8,
        "ODIs": 46,
        "T20Is": 20
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Sylhet International Cricket Stadium",
      "capacity": 18000,
      "location": "Sylhet",
      "facilities": ["Media Center", "Practice Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2007",
      "matchesHosted": {
        "Test Matches": 3,
        "ODIs": 24,
        "T20Is": 12
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Bangabandhu National Stadium",
      "capacity": 36000,
      "location": "Dhaka",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1954",
      "matchesHosted": {
        "ODIs": 7,
        "T20Is": 5
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Bangladesh Krira Shikkha Protisthan No 3 Ground",
      "capacity": 5000,
      "location": "Dhaka",
      "facilities": ["Training Ground", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "Not specified",
      "matchesHosted": {
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Cox's Bazar Cricket Stadium",
      "capacity": 7000,
      "location": "Cox's Bazar",
      "facilities": ["Media Center", "Training Facilities", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2007",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Bangladesh Krira Shikkha Protisthan Ground",
      "capacity": 5000,
      "location": "Dhaka",
      "facilities": ["Training Ground", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "Not specified",
      "matchesHosted": {
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "M.A. Aziz Stadium",
      "capacity": 15000,
      "location": "Chattogram",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "2008",
      "matchesHosted": {
        "ODIs": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Rajshahi Divisional Stadium",
      "capacity": 18000,
      "location": "Rajshahi",
      "facilities": ["Media Center", "Practice Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2007",
      "matchesHosted": {
        "ODIs": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Khan Shaheb Osman Ali Stadium",
      "capacity": 25000,
      "location": "Fatullah, Narayanganj",
      "facilities": ["Pavilion", "Media Center", "Practice Pitches"],
      "pitchType": "Grass",
      "inaugurated": "2006",
      "matchesHosted": {
        "ODIs": 5,
        "T20Is": 1
      }
    }
  ],
  "Canada": [
    {
      "name": "Toronto Cricket, Skating and Curling Club",
      "capacity": 5000,
      "location": "Toronto",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1908",
      "matchesHosted": {
        "ODIs": 12,
        "T20Is": 9
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "King City Cricket Club",
      "capacity": 4000,
      "location": "King City",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1996",
      "matchesHosted": {
        "ODIs": 9,
        "T20Is": 6
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Maple Leaf Cricket Club",
      "capacity": 3500,
      "location": "King City",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2004",
      "matchesHosted": {
        "ODIs": 7,
        "T20Is": 5
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Bayview Cricket Club",
      "capacity": 3000,
      "location": "Toronto",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1975",
      "matchesHosted": {
        "ODIs": 6,
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Victoria Park",
      "capacity": 2500,
      "location": "Kitchener",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1950",
      "matchesHosted": {
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Edmonton Cricket Ground",
      "capacity": 2000,
      "location": "Edmonton",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1989",
      "matchesHosted": {
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Winnipeg Cricket Oval",
      "capacity": 1800,
      "location": "Winnipeg",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1995",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Montreal Cricket Club",
      "capacity": 1500,
      "location": "Montreal",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1872",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Calgary Cricket Park",
      "capacity": 1200,
      "location": "Calgary",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "2008",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Ottawa Cricket Club",
      "capacity": 1000,
      "location": "Ottawa",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1963",
      "matchesHosted": {
        "T20Is": 1
      }
    }
  ],
  "England": [
    {
      "name": "Lord's Cricket Ground",
      "capacity": 30000,
      "location": "London",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1814",
      "matchesHosted": {
        "Test Matches": 136,
        "ODIs": 68,
        "T20Is": 38
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "The Oval",
      "capacity": 23500,
      "location": "London",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1845",
      "matchesHosted": {
        "Test Matches": 100,
        "ODIs": 72,
        "T20Is": 32
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Old Trafford Cricket Ground",
      "capacity": 26000,
      "location": "Manchester",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1857",
      "matchesHosted": {
        "Test Matches": 86,
        "ODIs": 66,
        "T20Is": 28
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Edgbaston Cricket Ground",
      "capacity": 25000,
      "location": "Birmingham",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1882",
      "matchesHosted": {
        "Test Matches": 57,
        "ODIs": 63,
        "T20Is": 22
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Headingley",
      "capacity": 18950,
      "location": "Leeds",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1890",
      "matchesHosted": {
        "Test Matches": 77,
        "ODIs": 42,
        "T20Is": 23
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Trent Bridge",
      "capacity": 17000,
      "location": "Nottingham",
      "facilities": ["Media Center", "Practice Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1841",
      "matchesHosted": {
        "Test Matches": 63,
        "ODIs": 47,
        "T20Is": 20
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Rose Bowl",
      "capacity": 15000,
      "location": "Southampton",
      "facilities": ["Media Center", "Indoor Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2001",
      "matchesHosted": {
        "ODIs": 31,
        "T20Is": 18
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Bristol County Ground",
      "capacity": 8000,
      "location": "Bristol",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1889",
      "matchesHosted": {
        "ODIs": 18,
        "T20Is": 15
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "County Ground, Taunton",
      "capacity": 6500,
      "location": "Taunton",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1882",
      "matchesHosted": {
        "ODIs": 17,
        "T20Is": 11
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "County Ground, Hove",
      "capacity": 7500,
      "location": "Hove",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1872",
      "matchesHosted": {
        "ODIs": 14,
        "T20Is": 9
      }
    }
  ],
  "India": [
    {
      "name": "Eden Gardens",
      "capacity": 66000,
      "location": "Kolkata, West Bengal",
      "facilities": ["Pavilion", "Media Center", "Practice Pitches"],
      "pitchType": "Grass",
      "inaugurated": "1864",
      "matchesHosted": [
        "Test Matches: 42",
        "ODIs: 30",
        "T20Is: 5"
      ],
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Wankhede Stadium",
      "capacity": 33000,
      "location": "Mumbai, Maharashtra",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1974",
      "matchesHosted": [
        "Test Matches: 25",
        "ODIs: 23",
        "T20Is: 7"
      ],
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "M. Chinnaswamy Stadium",
      "capacity": 38000,
      "location": "Bangalore, Karnataka",
      "facilities": ["Pavilion", "Media Center", "Indoor Nets"],
      "pitchType": "Grass",
      "inaugurated": "1969",
      "matchesHosted": [
        "Test Matches: 24",
        "ODIs: 20",
        "T20Is: 7"
      ],
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Rajiv Gandhi International Cricket Stadium",
      "capacity": 38000,
      "location": "Hyderabad, Telangana",
      "facilities": ["Pavilion", "Media Center", "Practice Nets"],
      "pitchType": "Grass",
      "inaugurated": "2003",
      "matchesHosted": [
        "Test Matches: 6",
        "ODIs: 18",
        "T20Is: 7"
      ],
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Sardar Vallabhbhai Patel Stadium",
      "capacity": 54000,
      "location": "Ahmedabad, Gujarat",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1982",
      "matchesHosted": [
        "Test Matches: 12",
        "ODIs: 24",
        "T20Is: 10"
      ],
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "MA Chidambaram Stadium",
      "capacity": 38000,
      "location": "Chennai, Tamil Nadu",
      "facilities": ["Pavilion", "Media Center", "Gymnasium"],
      "pitchType": "Grass",
      "inaugurated": "1916",
      "matchesHosted": [
        "Test Matches: 33",
        "ODIs: 24",
        "T20Is: 10"
      ],
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Holkar Cricket Stadium",
      "capacity": 30000,
      "location": "Indore, Madhya Pradesh",
      "facilities": ["Pavilion", "Media Center", "Training Ground"],
      "pitchType": "Grass",
      "inaugurated": "1990",
      "matchesHosted": [
        "Test Matches: 7",
        "ODIs: 16",
        "T20Is: 8"
      ],
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Greenfield International Stadium",
      "capacity": 50000,
      "location": "Thiruvananthapuram, Kerala",
      "facilities": ["Pavilion", "Media Center", "Indoor Training"],
      "pitchType": "Grass",
      "inaugurated": "2017",
      "matchesHosted": [
        "ODIs: 6",
        "T20Is: 4"
      ],
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Vidarbha Cricket Association Stadium",
      "capacity": 45000,
      "location": "Nagpur, Maharashtra",
      "facilities": ["Pavilion", "Media Center", "Practice Facilities"],
      "pitchType": "Grass",
      "inaugurated": "2008",
      "matchesHosted": [
        "Test Matches: 8",
        "ODIs: 13",
        "T20Is: 5"
      ],
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "PCA IS Bindra Stadium",
      "capacity": 26000,
      "location": "Mohali, Punjab",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1993",
      "matchesHosted": [
        "Test Matches: 13",
        "ODIs: 22",
        "T20Is: 8"
      ]
    }
  ],
  "Ireland": [
    {
      "name": "Malahide Cricket Club Ground",
      "capacity": 10000,
      "location": "Dublin",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1864",
      "matchesHosted": {
        "ODIs": 20,
        "T20Is": 15
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Stormont Cricket Ground",
      "capacity": 8000,
      "location": "Belfast",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1949",
      "matchesHosted": {
        "ODIs": 18,
        "T20Is": 12
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Bready Cricket Club Ground",
      "capacity": 6000,
      "location": "Bready",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2003",
      "matchesHosted": {
        "ODIs": 12,
        "T20Is": 9
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Clontarf Cricket Club Ground",
      "capacity": 5500,
      "location": "Dublin",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1936",
      "matchesHosted": {
        "ODIs": 10,
        "T20Is": 7
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Eglinton Cricket Club Ground",
      "capacity": 4500,
      "location": "Eglinton",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1972",
      "matchesHosted": {
        "ODIs": 8,
        "T20Is": 5
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "The Village, Malahide",
      "capacity": 4000,
      "location": "Dublin",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "ODIs": 6,
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Pembroke Cricket Club Ground",
      "capacity": 3500,
      "location": "Dublin",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1924",
      "matchesHosted": {
        "ODIs": 4,
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Civil Service Cricket Club Ground",
      "capacity": 3000,
      "location": "Stormont",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1903",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Oak Hill Cricket Club Ground",
      "capacity": 2500,
      "location": "Wicklow",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1998",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Railway Union Cricket Club Ground",
      "capacity": 2000,
      "location": "Dublin",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1919",
      "matchesHosted": {
        "ODIs": 1,
        "T20Is": 1
      }
    }
  ],
  "Kenya": [
    {
      "name": "Nairobi Gymkhana Club Ground",
      "capacity": 8000,
      "location": "Nairobi",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1935",
      "matchesHosted": {
        "ODIs": 22,
        "T20Is": 14
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Mombasa Sports Club Ground",
      "capacity": 6000,
      "location": "Mombasa",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1952",
      "matchesHosted": {
        "ODIs": 16,
        "T20Is": 10
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Ruaraka Sports Club Ground",
      "capacity": 5000,
      "location": "Nairobi",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1968",
      "matchesHosted": {
        "ODIs": 12,
        "T20Is": 8
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Eldoret Sports Club Ground",
      "capacity": 4000,
      "location": "Eldoret",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1976",
      "matchesHosted": {
        "ODIs": 8,
        "T20Is": 6
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Nairobi Jafferys Sports Club Ground",
      "capacity": 3500,
      "location": "Nairobi",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1992",
      "matchesHosted": {
        "ODIs": 6,
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Kericho Green Stadium",
      "capacity": 3000,
      "location": "Kericho",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1985",
      "matchesHosted": {
        "ODIs": 4,
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Nakuru Athletic Club Ground",
      "capacity": 2500,
      "location": "Nakuru",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1970",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Kisumu Simba Club Ground",
      "capacity": 2000,
      "location": "Kisumu",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1991",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Thika Municipal Stadium",
      "capacity": 1500,
      "location": "Thika",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1980",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Kakamega Showground",
      "capacity": 1000,
      "location": "Kakamega",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1987",
      "matchesHosted": {
        "T20Is": 1
      }
    }
  ],
  "Namibia": [
    {
      "name": "Wanderers Cricket Ground",
      "capacity": 8000,
      "location": "Windhoek",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1961",
      "matchesHosted": {
        "ODIs": 18,
        "T20Is": 11
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "United Cricket Club Ground",
      "capacity": 6000,
      "location": "Windhoek",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1975",
      "matchesHosted": {
        "ODIs": 14,
        "T20Is": 9
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Trustco United Sports Ground",
      "capacity": 5000,
      "location": "Windhoek",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1990",
      "matchesHosted": {
        "ODIs": 10,
        "T20Is": 6
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Rehoboth Cricket Club Ground",
      "capacity": 4000,
      "location": "Rehoboth",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1982",
      "matchesHosted": {
        "ODIs": 8,
        "T20Is": 5
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Walvis Bay Cricket Ground",
      "capacity": 3500,
      "location": "Walvis Bay",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1978",
      "matchesHosted": {
        "ODIs": 6,
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Luderitz Cricket Club Ground",
      "capacity": 3000,
      "location": "Luderitz",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1987",
      "matchesHosted": {
        "ODIs": 4,
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Keetmanshoop Cricket Club Ground",
      "capacity": 2500,
      "location": "Keetmanshoop",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1995",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Oshakati Cricket Club Ground",
      "capacity": 2000,
      "location": "Oshakati",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2000",
      "matchesHosted": {
        "ODIs": 1,
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Outjo Cricket Club Ground",
      "capacity": 1500,
      "location": "Outjo",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1998",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Otjiwarongo Cricket Club Ground",
      "capacity": 1000,
      "location": "Otjiwarongo",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2005",
      "matchesHosted": {
        "T20Is": 1
      }
    }
  ],
  "Nepal": [
    {
      "name": "Tribhuvan University International Cricket Ground",
      "capacity": 3000,
      "location": "Kirtipur, Kathmandu",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2019",
      "matchesHosted": {
        "T20Is": 7
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Kirtipur Cricket Ground",
      "capacity": 2000,
      "location": "Kirtipur, Kathmandu",
      "facilities": ["Training Ground", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2002",
      "matchesHosted": {
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Pokhara Rangasala",
      "capacity": 10000,
      "location": "Pokhara",
      "facilities": ["Media Center", "Training Facilities", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2015",
      "matchesHosted": {
        "T20Is": 5
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "TU Cricket Ground",
      "capacity": 2500,
      "location": "Kirtipur, Kathmandu",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2000",
      "matchesHosted": {
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Fapla Cricket Ground",
      "capacity": 5000,
      "location": "Fapla",
      "facilities": ["Media Center", "Practice Pitches", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2005",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Dhangadhi Cricket Academy",
      "capacity": 2000,
      "location": "Dhangadhi",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2008",
      "matchesHosted": {
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Tikapur Cricket Ground",
      "capacity": 3000,
      "location": "Tikapur",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Biratnagar Cricket Ground",
      "capacity": 5000,
      "location": "Biratnagar",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2003",
      "matchesHosted": {
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Mangalbare Cricket Ground",
      "capacity": 3000,
      "location": "Mangalbare",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2011",
      "matchesHosted": {
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Birgunj Cricket Ground",
      "capacity": 4000,
      "location": "Birgunj",
      "facilities": ["Media Center", "Training Facilities", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2006",
      "matchesHosted": {
        "T20Is": 1
      }
    }
  ],
  "Netherlands": [
    {
      "name": "Amsterdam Cricket Ground",
      "capacity": 8000,
      "location": "Amsterdam",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1957",
      "matchesHosted": {
        "ODIs": 22,
        "T20Is": 14
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Rotterdam Cricket Club Ground",
      "capacity": 6000,
      "location": "Rotterdam",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1962",
      "matchesHosted": {
        "ODIs": 16,
        "T20Is": 10
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "The Hague Cricket Ground",
      "capacity": 5000,
      "location": "The Hague",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1970",
      "matchesHosted": {
        "ODIs": 12,
        "T20Is": 8
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Utrecht Cricket Club Ground",
      "capacity": 4000,
      "location": "Utrecht",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1975",
      "matchesHosted": {
        "ODIs": 8,
        "T20Is": 6
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Haarlem Cricket Club Ground",
      "capacity": 3500,
      "location": "Haarlem",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1980",
      "matchesHosted": {
        "ODIs": 6,
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Amstelveen Cricket Club Ground",
      "capacity": 3000,
      "location": "Amstelveen",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1985",
      "matchesHosted": {
        "ODIs": 4,
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Delft Cricket Club Ground",
      "capacity": 2500,
      "location": "Delft",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1990",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Leiden Cricket Club Ground",
      "capacity": 2000,
      "location": "Leiden",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1995",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Eindhoven Cricket Club Ground",
      "capacity": 1500,
      "location": "Eindhoven",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "2000",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Groningen Cricket Club Ground",
      "capacity": 1000,
      "location": "Groningen",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2005",
      "matchesHosted": {
        "T20Is": 1
      }
    }
  ],
  "New Zealand": [
    {
      "name": "Eden Park",
      "capacity": 50000,
      "location": "Auckland",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1900",
      "matchesHosted": {
        "Tests": 65,
        "ODIs": 140,
        "T20Is": 38
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Hagley Oval",
      "capacity": 20000,
      "location": "Christchurch",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1886",
      "matchesHosted": {
        "Tests": 27,
        "ODIs": 44,
        "T20Is": 17
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Wellington Regional Stadium",
      "capacity": 37000,
      "location": "Wellington",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1999",
      "matchesHosted": {
        "Tests": 24,
        "ODIs": 49,
        "T20Is": 21
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Seddon Park",
      "capacity": 10000,
      "location": "Hamilton",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1950",
      "matchesHosted": {
        "Tests": 31,
        "ODIs": 65,
        "T20Is": 19
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Basin Reserve",
      "capacity": 11000,
      "location": "Wellington",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1886",
      "matchesHosted": {
        "Tests": 60,
        "ODIs": 34,
        "T20Is": 12
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Bay Oval",
      "capacity": 10000,
      "location": "Mount Maunganui",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2004",
      "matchesHosted": {
        "ODIs": 14,
        "T20Is": 9
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "McLean Park",
      "capacity": 10000,
      "location": "Napier",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1951",
      "matchesHosted": {
        "ODIs": 51,
        "T20Is": 16
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "University Oval",
      "capacity": 3500,
      "location": "Dunedin",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2003",
      "matchesHosted": {
        "Tests": 7,
        "ODIs": 24,
        "T20Is": 8
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Cobham Oval",
      "capacity": 7000,
      "location": "Whangarei",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2001",
      "matchesHosted": {
        "ODIs": 9,
        "T20Is": 6
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Pukekura Park",
      "capacity": 7000,
      "location": "New Plymouth",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1947",
      "matchesHosted": {
        "ODIs": 5,
        "T20Is": 4
      }
    }
  ],
  "Oman": [
    {
      "name": "Al Amerat Cricket Stadium",
      "capacity": 12000,
      "location": "Muscat",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2012",
      "matchesHosted": {
        "ODIs": 10,
        "T20Is": 8
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Al Emarat Cricket Ground",
      "capacity": 10000,
      "location": "Muscat",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "ODIs": 8,
        "T20Is": 6
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Oman Cricket Academy Ground",
      "capacity": 8000,
      "location": "Muscat",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2008",
      "matchesHosted": {
        "ODIs": 6,
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Muscat Cricket Ground",
      "capacity": 6000,
      "location": "Muscat",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2005",
      "matchesHosted": {
        "ODIs": 4,
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Sohar Cricket Ground",
      "capacity": 5000,
      "location": "Sohar",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "2014",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Salalah Cricket Ground",
      "capacity": 4000,
      "location": "Salalah",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2011",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Nizwa Cricket Ground",
      "capacity": 3000,
      "location": "Nizwa",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2013",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Al Buraimi Cricket Ground",
      "capacity": 2500,
      "location": "Al Buraimi",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2015",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Ibri Cricket Ground",
      "capacity": 2000,
      "location": "Ibri",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2016",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Rustaq Cricket Ground",
      "capacity": 1500,
      "location": "Rustaq",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "2017",
      "matchesHosted": {
        "T20Is": 1
      }
    }
  ],
  "Pakistan": [
    {
      "name": "National Stadium Karachi",
      "capacity": 34000,
      "location": "Karachi",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1955",
      "matchesHosted": {
        "Test Matches": 41,
        "ODIs": 75,
        "T20Is": 12
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Gaddafi Stadium",
      "capacity": 27000,
      "location": "Lahore",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1959",
      "matchesHosted": {
        "Test Matches": 41,
        "ODIs": 96,
        "T20Is": 22
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Rawalpindi Cricket Stadium",
      "capacity": 15000,
      "location": "Rawalpindi",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1992",
      "matchesHosted": {
        "Test Matches": 9,
        "ODIs": 23,
        "T20Is": 8
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Multan Cricket Stadium",
      "capacity": 35000,
      "location": "Multan",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2001",
      "matchesHosted": {
        "Test Matches": 12,
        "ODIs": 27,
        "T20Is": 9
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Arbab Niaz Stadium",
      "capacity": 20000,
      "location": "Peshawar",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1984",
      "matchesHosted": {
        "Test Matches": 6,
        "ODIs": 15,
        "T20Is": 5
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Iqbal Stadium",
      "capacity": 18000,
      "location": "Faisalabad",
      "facilities": ["Media Center", "Indoor Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1978",
      "matchesHosted": {
        "Test Matches": 18,
        "ODIs": 29,
        "T20Is": 8
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Rawalakot Cricket Stadium",
      "capacity": 10000,
      "location": "Rawalakot",
      "facilities": ["Media Center", "Practice Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2008",
      "matchesHosted": {
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Abbottabad Cricket Stadium",
      "capacity": 8000,
      "location": "Abbottabad",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "2007",
      "matchesHosted": {
        "ODIs": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Sukkur Cricket Stadium",
      "capacity": 15000,
      "location": "Sukkur",
      "facilities": ["Media Center", "Practice Pitches", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Multan Cricket Stadium (New)",
      "capacity": 15000,
      "location": "Multan",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2020",
      "matchesHosted": {
        "ODIs": 1
      }
    }
  ],
  "Scotland": [
    {
      "name": "Grange Cricket Club",
      "capacity": 5000,
      "location": "Edinburgh",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1832",
      "matchesHosted": {
        "ODIs": 11,
        "T20Is": 7
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Mannofield Park",
      "capacity": 7000,
      "location": "Aberdeen",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1897",
      "matchesHosted": {
        "ODIs": 6,
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Raeburn Place",
      "capacity": 4000,
      "location": "Edinburgh",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1855",
      "matchesHosted": {
        "ODIs": 4,
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "New Williamfield",
      "capacity": 2500,
      "location": "Stirling",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1900",
      "matchesHosted": {
        "ODIs": 3,
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Cambusdoon New Ground",
      "capacity": 3000,
      "location": "Ayr",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1888",
      "matchesHosted": {
        "ODIs": 2,
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Glenpark",
      "capacity": 1500,
      "location": "Greenock",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1871",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Arbroath United Cricket Club",
      "capacity": 2000,
      "location": "Arbroath",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1856",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Duthie Park",
      "capacity": 1200,
      "location": "Aberdeen",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1905",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Fort Hill Cricket Club",
      "capacity": 1000,
      "location": "Inverness",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1875",
      "matchesHosted": {
        "T20Is": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Hamilton Crescent",
      "capacity": 3000,
      "location": "Glasgow",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1845",
      "matchesHosted": {
        "ODIs": 1
      }
    }
  ],
  "South Africa": [
    {
      "name": "Newlands Cricket Ground",
      "capacity": 25000,
      "location": "Cape Town",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1888",
      "matchesHosted": {
        "Test Matches": 64,
        "ODIs": 44,
        "T20Is": 19
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Wanderers Stadium",
      "capacity": 34000,
      "location": "Johannesburg",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1956",
      "matchesHosted": {
        "Test Matches": 41,
        "ODIs": 40,
        "T20Is": 20
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Kingsmead Cricket Ground",
      "capacity": 25000,
      "location": "Durban",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1923",
      "matchesHosted": {
        "Test Matches": 41,
        "ODIs": 26,
        "T20Is": 14
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "St George's Park",
      "capacity": 19000,
      "location": "Port Elizabeth",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1882",
      "matchesHosted": {
        "Test Matches": 28,
        "ODIs": 35,
        "T20Is": 18
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "SuperSport Park",
      "capacity": 22000,
      "location": "Centurion",
      "facilities": ["Media Center", "Indoor Nets", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1986",
      "matchesHosted": {
        "Test Matches": 25,
        "ODIs": 37,
        "T20Is": 18
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Buffalo Park",
      "capacity": 15000,
      "location": "East London",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1991",
      "matchesHosted": {
        "Test Matches": 9,
        "ODIs": 28,
        "T20Is": 10
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Mangaung Oval",
      "capacity": 20000,
      "location": "Bloemfontein",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1989",
      "matchesHosted": {
        "Test Matches": 9,
        "ODIs": 13,
        "T20Is": 9
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Willowmoore Park",
      "capacity": 10000,
      "location": "Benoni",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1924",
      "matchesHosted": {
        "Test Matches": 1,
        "ODIs": 11,
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "De Beers Diamond Oval",
      "capacity": 11000,
      "location": "Kimberley",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1988",
      "matchesHosted": {
        "ODIs": 13,
        "T20Is": 6
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Moses Mabhida Stadium",
      "capacity": 54000,
      "location": "Durban",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "T20Is": 2
      }
    }
  ],
  "Sri Lanka": [
    {
      "name": "R. Premadasa Stadium",
      "capacity": 35000,
      "location": "Colombo",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1986",
      "matchesHosted": {
        "Test Matches": 21,
        "ODIs": 136,
        "T20Is": 38
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Pallekele International Cricket Stadium",
      "capacity": 35000,
      "location": "Kandy",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2009",
      "matchesHosted": {
        "Test Matches": 10,
        "ODIs": 41,
        "T20Is": 27
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Galle International Stadium",
      "capacity": 20000,
      "location": "Galle",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1998",
      "matchesHosted": {
        "Test Matches": 33,
        "ODIs": 29,
        "T20Is": 7
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Mahinda Rajapaksa International Cricket Stadium",
      "capacity": 35000,
      "location": "Hambantota",
      "facilities": ["Media Center", "Indoor Nets", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "2011",
      "matchesHosted": {
        "Test Matches": 9,
        "ODIs": 35,
        "T20Is": 26
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Asgiriya Stadium",
      "capacity": 10000,
      "location": "Kandy",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1982",
      "matchesHosted": {
        "Test Matches": 13,
        "ODIs": 16
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Sinhalese Sports Club Ground",
      "capacity": 10000,
      "location": "Colombo",
      "facilities": ["Pavilion", "Media Center", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1899",
      "matchesHosted": {
        "Test Matches": 40,
        "ODIs": 32
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Sara Stadium",
      "capacity": 15000,
      "location": "Colombo",
      "facilities": ["Media Center", "Practice Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1979",
      "matchesHosted": {
        "ODIs": 13,
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Rangiri Dambulla International Stadium",
      "capacity": 18000,
      "location": "Dambulla",
      "facilities": ["Media Center", "Practice Pitches", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2000",
      "matchesHosted": {
        "ODIs": 38,
        "T20Is": 16
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Nondescripts Cricket Club Ground",
      "capacity": 5000,
      "location": "Colombo",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1937",
      "matchesHosted": {
        "ODIs": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Moratuwa Sports Complex",
      "capacity": 10000,
      "location": "Moratuwa",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2003",
      "matchesHosted": {
        "ODIs": 2
      }
    }
  ],
  "UAE": [
    {
      "name": "Sheikh Zayed Cricket Stadium",
      "capacity": 20000,
      "location": "Abu Dhabi",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2004",
      "matchesHosted": {
        "Test Matches": 23,
        "ODIs": 64,
        "T20Is": 32
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Dubai International Cricket Stadium",
      "capacity": 25000,
      "location": "Dubai",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "2009",
      "matchesHosted": {
        "Test Matches": 20,
        "ODIs": 79,
        "T20Is": 39
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Sharjah Cricket Stadium",
      "capacity": 17000,
      "location": "Sharjah",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1982",
      "matchesHosted": {
        "Test Matches": 18,
        "ODIs": 236,
        "T20Is": 72
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Zayed Cricket Stadium Nursery 1",
      "capacity": 5000,
      "location": "Abu Dhabi",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2007",
      "matchesHosted": {
        "T20Is": 7
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Zayed Cricket Stadium Nursery 2",
      "capacity": 5000,
      "location": "Abu Dhabi",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2007",
      "matchesHosted": {
        "T20Is": 5
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "ICC Academy Ground No. 1",
      "capacity": 1500,
      "location": "Dubai",
      "facilities": ["Media Center", "Practice Pitches", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "ODIs": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "ICC Academy Ground No. 2",
      "capacity": 1500,
      "location": "Dubai",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "ODIs": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "ICC Academy Ground No. 3",
      "capacity": 1500,
      "location": "Dubai",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "ODIs": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "ICC Academy Ground No. 4",
      "capacity": 1500,
      "location": "Dubai",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "ODIs": 1
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "ICC Academy Ground No. 5",
      "capacity": 1500,
      "location": "Dubai",
      "facilities": ["Media Center", "Practice Pitches", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "ODIs": 2
      }
    }
  ],
  "Zimbabwe": [
    {
      "name": "Harare Sports Club",
      "capacity": 10000,
      "location": "Harare",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1900",
      "matchesHosted": {
        "Test Matches": 29,
        "ODIs": 102,
        "T20Is": 28
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Queens Sports Club",
      "capacity": 9000,
      "location": "Bulawayo",
      "facilities": ["Pavilion", "Media Center", "Fitness Center"],
      "pitchType": "Grass",
      "inaugurated": "1902",
      "matchesHosted": {
        "Test Matches": 30,
        "ODIs": 74,
        "T20Is": 22
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Mutare Sports Club",
      "capacity": 6000,
      "location": "Mutare",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1923",
      "matchesHosted": {
        "ODIs": 18,
        "T20Is": 15
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Kwekwe Sports Club",
      "capacity": 4500,
      "location": "Kwekwe",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1965",
      "matchesHosted": {
        "ODIs": 10,
        "T20Is": 8
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Queensmead Stadium",
      "capacity": 4000,
      "location": "Bulawayo",
      "facilities": ["Pavilion", "Media Center", "Training Facilities"],
      "pitchType": "Grass",
      "inaugurated": "1953",
      "matchesHosted": {
        "ODIs": 8,
        "T20Is": 7
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Old Hararians Sports Club",
      "capacity": 3500,
      "location": "Harare",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1958",
      "matchesHosted": {
        "T20Is": 5
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Alexandra Sports Club",
      "capacity": 3000,
      "location": "Harare",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "1914",
      "matchesHosted": {
        "T20Is": 4
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Gwanzura Stadium",
      "capacity": 2500,
      "location": "Harare",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "1982",
      "matchesHosted": {
        "T20Is": 3
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "Hartsfield T20 Cricket Ground",
      "capacity": 2000,
      "location": "Bulawayo",
      "facilities": ["Media Center", "Indoor Nets", "Corporate Boxes"],
      "pitchType": "Grass",
      "inaugurated": "2000",
      "matchesHosted": {
        "T20Is": 2
      }
      ,
      "battingCondition": [9, 7],
      "betterFor": 1,
      "outfieldType": 4,
    },
    {
      "name": "ZimCric Academy Ground",
      "capacity": 1500,
      "location": "Harare",
      "facilities": ["Media Center", "Practice Nets", "Spectator Area"],
      "pitchType": "Grass",
      "inaugurated": "2010",
      "matchesHosted": {
        "T20Is": 1
      }
    }
  ],
}
export { stadiums };
