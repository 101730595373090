import React, { useState } from "react";

import { motion } from "framer-motion"

const HomePage = () => {
    const [email, setEmail] = useState("");

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            <h2>Home: {{ email }}</h2>
        </motion.div>
    );
};

export default HomePage;

