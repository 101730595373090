import { BrowserRouter, Routes, Route } from "react-router-dom";
import PathManager from "./PathManager";

import { } from 'framer-motion'

function App() {

    return (
        <BrowserRouter>
            <PathManager />
        </BrowserRouter>
    );
}

export default App;

