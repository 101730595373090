const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^(?:\+8801\d{9}|\d{11})$/;
const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const yearRestriction = 13;
export const validateEmail = (email) => emailRegex.test(email);
export const validatePhone = (phoneNumber) => phoneRegex.test(phoneNumber);
export const validateDateOfBirth = (dob) => dateRegex.test(dob);
export const isAdult = (birthdate) => {
  const birthdateObj = new Date(birthdate);
  const today = new Date();
  const age = today.getFullYear() - birthdateObj.getFullYear();
  return age >= yearRestriction;
};
