let matchStatusEnum =
{
    intro: 0,
    ChooseMatchType: 1,
    ChooseOwnTeam: 2,
    ChooseOpponent: 3,
    ChooseMatchLocation: 4,
    ChooseStadium: 5,
    ChooseCommentator: 6,
    TossTime: 7,
    TossResult: 8,
    TeamOneBat: 9,
    TeamTwoBat: 10,
    MatchEnd: 11,
};
export { matchStatusEnum };
