// Login.js
import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import axios from "axios";
import "../../css/common.css";
import { registrationColumn } from "../../Helper/enum";
import {
  validateDateOfBirth,
  validateEmail,
  validatePhone,
  isAdult
} from "../../Helper/commonFunction";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [error, setError] = useState(null);
  const [problemColumn, setProblemColumn] = useState(null);

  const navigate = useNavigate();

  const handleRegistration = async () => {
    if (isRegistrationDataOK()) {
      setProblemColumn(null);
      setError(null);
      try {
        let formData = {
          name: name,
          email: email,
          phone: phoneNumber,
          address: address,
          password: password,
          dob: dob,
          gender: gender
        };
        console.log(formData);
        const response = await axios.post(
          "https://snigtus.com/Api/new_user_regi/index.php",
          // "http://localhost/Api/new_user_regi",
          formData
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
      // try {
      //   // Create a new user with additional information
      //   await firebase.auth().createUserWithEmailAndPassword(email, password);
      //   const user = firebase.auth().currentUser;

      //   // Update additional user information
      //   await user.updateProfile({
      //     displayName: name
      //   });

      //   // User registered successfully
      //   console.log("User registered successfully!");
      // } catch (error) {
      //   setError(error.message);
      //   console.error("Error registering user:", error.message);
      // }
    }
  };

  const emptyString = (data) => {
    return data === "";
  };

  const isRegistrationDataOK = () => {
    let errorMessage = "";
    if (emptyString(name)) {
      setProblemColumn(registrationColumn.Name);
      errorMessage = "Please enter your name";
    } else if (emptyString(email)) {
      setProblemColumn(registrationColumn.Email);
      errorMessage = "Please enter your email";
    } else if (!validateEmail(email)) {
      errorMessage = "Please enter a valid email address";
      setProblemColumn(registrationColumn.Email);
    } else if (emptyString(phoneNumber)) {
      errorMessage = "Please enter your phone number";
      setProblemColumn(registrationColumn.Phone);
    } else if (!validatePhone(phoneNumber)) {
      errorMessage =
        "Please enter a 11 digit valid phone number. Example 01672317848";
      setProblemColumn(registrationColumn.phoneNumber);
    } else if (emptyString(address)) {
      errorMessage = "Please enter your address";
      setProblemColumn(registrationColumn.Address);
    } else if (emptyString(password)) {
      setProblemColumn(registrationColumn.Password);
      errorMessage = "Please enter your password";
    } else if (password.length < 6) {
      errorMessage = "Password must be at least 6 characters long";
      setProblemColumn(registrationColumn.Password);
    } else if (emptyString(dob)) {
      setProblemColumn(registrationColumn.DOB);
      errorMessage = "Please enter your date of birth";
    } else if (!validateDateOfBirth(dob)) {
      errorMessage = "Please enter a valid date of birth (YYYY-MM-DD)";
      setProblemColumn(registrationColumn.DOB);
    } else if (!isAdult(dob)) {
      errorMessage = "People under 13 are not allowed join";
      setProblemColumn(registrationColumn.DOB);
    } else if (emptyString(gender)) {
      errorMessage = "Please select your gender";
      setProblemColumn(registrationColumn.Gender);
    }
    if (errorMessage !== "") {
      setError(errorMessage);
      return false;
    } else return true;
  };

  const switchToLogin = () => {
    navigate("/login");
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="d-flex justify-content-center align-items-center vh-100 vw-100 bg-beige-gradient"
    >
      <div className="box p-5 border border-rounded bg-glass">
        <h1 className="text-center">
          Join <span className="font-volcano">SnigTus</span>
        </h1>
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="input-group">
              <span
                className={`input-group-text ${
                  problemColumn === registrationColumn.Name
                    ? "bg-danger text-white"
                    : "bg-cornflowerblue"
                }`}
              >
                Name{" "}
              </span>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                id="name"
                placeholder="Enter name"
                name="name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-group">
              <span
                className={`input-group-text ${
                  problemColumn === registrationColumn.Email
                    ? "bg-danger text-white"
                    : "bg-cornflowerblue"
                }`}
              >
                Email{" "}
              </span>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                id="email"
                placeholder="Enter email"
                name="email"
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="input-group">
              <span
                className={`input-group-text ${
                  problemColumn === registrationColumn.Phone
                    ? "bg-danger text-white"
                    : "bg-cornflowerblue"
                }`}
              >
                Phone{" "}
              </span>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="form-control"
                id="phoneNumber"
                placeholder="Enter phone number"
                name="phoneNumber"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-group">
              <span
                className={`input-group-text ${
                  problemColumn === registrationColumn.Address
                    ? "bg-danger text-white"
                    : "bg-cornflowerblue"
                }`}
              >
                Address{" "}
              </span>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="form-control"
                id="address"
                placeholder="Enter address"
                name="address"
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="input-group">
              <span
                className={`input-group-text ${
                  problemColumn === registrationColumn.Password
                    ? "bg-danger text-white"
                    : "bg-cornflowerblue"
                }`}
              >
                Password{" "}
              </span>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                id="password"
                placeholder="Enter password"
                name="password"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-group">
              <span
                className={`input-group-text ${
                  problemColumn === registrationColumn.DOB
                    ? "bg-danger text-white"
                    : "bg-cornflowerblue"
                }`}
              >
                Date of Birth{" "}
              </span>
              <input
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                className="form-control"
                id="dob"
                placeholder="Enter date of birth"
                name="dob"
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="input-group">
              <span
                className={`input-group-text ${
                  problemColumn === registrationColumn.Gender
                    ? "bg-danger text-white"
                    : "bg-cornflowerblue"
                }`}
              >
                Gender{" "}
              </span>
              <div className="form-control">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    id="male"
                    checked={gender === "male"}
                    onChange={() => setGender("male")}
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="male">
                    Male
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    id="female"
                    checked={gender === "female"}
                    onChange={() => setGender("female")}
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="female">
                    Female
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name="gender"
                    value="other"
                    id="other"
                    checked={gender === "other"}
                    onChange={() => setGender("other")}
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="other">
                    Other
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-6">
            <button
              onClick={handleRegistration}
              className="btn btn-primary w-100"
            >
              Register
            </button>
          </div>
          <div className="col-sm-6">
            <button onClick={switchToLogin} className="btn btn-secondary w-100">
              Already a Menber
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3"></div>

        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </motion.div>
  );
};

export default RegisterPage;
