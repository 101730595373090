import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import LoginPage from "./Page/Auth/LoginPage";
import RegisterPage from "./Page/Auth/RegisterPage";
import CommonErrorPage from "./Page/Error/CommonErrorPage";
import HomePage from "./Page/User/HomePage";
import ProfilePage from "./Page/User/ProfilePage";
import GamePage from "./Page/Cricket/GamePage";
import { AnimatePresence } from "framer-motion";

function PathManager() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/new" element={<RegisterPage />} />
        <Route path="/error" element={<CommonErrorPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/game" element={<GamePage />} />
      </Routes>
    </AnimatePresence>
  );
}

export default PathManager;
