let outVoice = [
    [
        "$$$$$ mistimes the shot and skies it. Fielder takes an easy catch. Out!",
        "Batsman $$$$$ goes for a big hit, but it's caught in the deep. Out!",
        "$$$$$ tries to clear the boundary, but the fielder catches it. Out!",
        "A lofted shot by $$$$$, but it's caught brilliantly by the fielder. Out!",
        "$$$$$ tries to flick it, but the fielder takes a diving catch. Out!",
        "$$$$$ goes for a big one, but it's caught on the boundary. Out!",
        "Batsman $$$$$ miscues the shot, and it's taken comfortably by the fielder. Out!",
        "A top edge by $$$$$, and the fielder doesn't drop it. Out!",
        "$$$$$ tries to cut it, but it goes straight to the fielder. Out!",
        "$$$$$ plays it straight into the hands of the fielder. Out!",
        "A high catch taken by the fielder as $$$$$ skies it. Out!",
        "Batsman $$$$$ hits it in the air, and the fielder catches it safely. Out!",
        "$$$$$ goes for a big shot, but it's caught near the boundary. Out!",
        "$$$$$ tries to clear the infield, but it's caught by a fielder. Out!",
        "$$$$$ plays it in the air, and it's caught by the fielder. Out!",
        "$$$$$ mishits it, and the fielder takes an easy catch. Out!",
        "$$$$$ goes for a big swing, but it's caught by the fielder. Out!",
        "$$$$$ skies it, and the fielder judges it well to take the catch. Out!",
        "Batsman $$$$$ tries to go over the top, but it's caught in the deep. Out!",
        "$$$$$ mistimes the shot, and the fielder makes no mistake. Out!"
    ],
    [
        "$$$$$ pushes it to the fielder and calls ##### for a quick single, but the fielder's throw is accurate. Run-out!",
        "$$$$$ drives it to mid-on and attempts a single, but the fielder's throw is spot-on. Run-out!",
        "$$$$$ plays it to point and calls ##### for a run, but the direct hit finds ##### short. Run-out!",
        "$$$$$ nudges it to the leg side and attempts a single, but the fielder's throw hits the stumps. Run-out!",
        "$$$$$ pushes it gently and sets off for a run, but a brilliant throw catches ##### short. Run-out!",
        "$$$$$ plays it to cover and takes off for a quick single, but the direct hit gets #####. Run-out!",
        "$$$$$ drives it to mid-off and calls ##### for a run, but the fielder's throw is too quick. Run-out!",
        "$$$$$ flicks it to square leg and attempts a single, but a direct hit ends #####'s innings. Run-out!",
        "$$$$$ plays it to mid-wicket and takes off for a run, but the fielder's throw is accurate. Run-out!",
        "$$$$$ pushes it to point and calls ##### for a quick single, but the fielder's throw is on target. Run-out!",
        "$$$$$ pushes it to cover and attempts a run, but a quick pick-up and throw results in #####'s run-out. Run-out!",
        "$$$$$ plays it to mid-on and sets off for a run, but the direct hit spells the end for #####. Run-out!",
        "$$$$$ flicks it to square leg and calls ##### for a run, but the fielder's throw is perfect. Run-out!",
        "$$$$$ pushes it to mid-wicket and takes off, but the direct hit finds ##### short of the crease. Run-out!",
        "$$$$$ plays it to the leg side and calls ##### for a run, but the fielder's throw ends #####'s stay. Run-out!",
        "$$$$$ nudges it to backward point and sets off, but a sharp throw finds ##### short of the crease. Run-out!",
        "$$$$$ plays it to cover and takes off for a quick single, but a direct hit results in #####'s dismissal. Run-out!",
        "$$$$$ pushes it gently and attempts a run, but the fielder's throw hits the stumps. Run-out!",
        "$$$$$ drives it to mid-off and calls ##### for a run, but the fielder's throw is too quick. Run-out!",
        "$$$$$ plays it to mid-wicket and takes off for a run, but the fielder's throw is accurate. Run-out!"
    ],
    [
        "$$$$$ is struck on the pad and the umpire raises the finger! LBW!",
        "A huge shout for LBW, and $$$$$ is given out by the umpire!",
        "$$$$$ misses the ball, and the umpire has no hesitation in giving LBW!",
        "The ball swings in, hits $$$$$ on the pad, and it's a clear LBW decision!",
        "$$$$$ plays down the wrong line, and he's trapped in front. LBW!",
        "$$$$$ attempts a sweep shot but misses, and he's plumb in front. LBW!",
        "$$$$$ is caught on the crease, and the umpire raises the finger for LBW!",
        "A great inswinger, and $$$$$ is caught in front. LBW!",
        "$$$$$ tries to defend but is struck low on the pad. The umpire says LBW!",
        "The ball keeps low, hits $$$$$ on the pad, and the umpire gives LBW!",
        "$$$$$ is hit on the front pad, and the appeal is upheld. LBW!",
        "A full delivery, and $$$$$ is hit in line with the stumps. LBW!",
        "$$$$$ plays for turn, but it's straight and he's out LBW!",
        "The ball nips back, strikes $$$$$ on the pad, and it's LBW!",
        "$$$$$ is wrapped on the pad, and the umpire raises the finger for LBW!",
        "$$$$$ is caught in front of the stumps, and he's out LBW!",
        "$$$$$ misses the ball, and he's plumb in front. LBW!",
        "The ball sneaks through, hits $$$$$ on the pad, and it's LBW!",
        "$$$$$ tries to work it to the leg side but misses. The umpire gives LBW!",
        "$$$$$ is struck on the pad, and the finger goes up. LBW!"
    ],
    [
        "$$$$$ plays all around it and hears the sound of timber! Bowled!",
        "A beautiful delivery, and it's through $$$$$'s defense! Bowled!",
        "$$$$$ is beaten comprehensively, and the stumps are shattered! Bowled!",
        "$$$$$ misjudges the line, and the ball crashes into the stumps! Bowled!",
        "The ball sneaks through $$$$$'s defense and hits the stumps! Bowled!",
        "A fantastic delivery, and it cleans up $$$$$! Bowled!",
        "$$$$$ tries to defend, but the ball sneaks through and hits the stumps! Bowled!",
        "$$$$$ is bowled all ends up by a beauty! Bowled!",
        "A peach of a delivery, and it's through $$$$$! Bowled!",
        "$$$$$ is beaten for pace, and the stumps are shattered! Bowled!",
        "$$$$$ plays down the wrong line, and the stumps are knocked over! Bowled!",
        "A superb delivery, and it's too good for $$$$$! Bowled!",
        "$$$$$ tries to cover the stumps, but the ball crashes into them! Bowled!",
        "A brilliant delivery, and it cleans up $$$$$! Bowled!",
        "$$$$$ is beaten in flight, and the stumps are disturbed! Bowled!",
        "$$$$$ tries to block it, but the ball goes through and hits the stumps! Bowled!",
        "$$$$$ misreads the length, and the stumps are rattled! Bowled!",
        "A perfect yorker, and $$$$$ can't keep it out! Bowled!",
        "$$$$$ is bowled as the ball crashes into the stumps! Bowled!",
        "A magical delivery, and it goes through $$$$$'s defense! Bowled!"
    ],
    [
        "$$$$$ dances down the wicket and misses. The keeper is quick, and $$$$$ is stumped!",
        "A moment of hesitation by $$$$$, and the keeper whips off the bails. Stumped!",
        "$$$$$ comes down the track but is beaten by the spin. Stumped by the keeper!",
        "A wild swing by $$$$$, and he loses his balance. Stumped by the keeper!",
        "$$$$$ is deceived by the flight, and the keeper makes no mistake. Stumped!",
        "$$$$$ steps out but misses. The keeper does the rest. Stumped!",
        "A brilliant piece of keeping! $$$$$ is stumped by inches!",
        "$$$$$ advances, and the keeper dislodges the bails. Stumped!",
        "$$$$$ is caught out of his crease, and the keeper seizes the opportunity. Stumped!",
        "$$$$$ is beaten in the air, and the keeper is quick to react. Stumped!",
        "$$$$$ misses the ball while attempting a big shot, and he's stumped!",
        "$$$$$ loses balance while playing the shot, and the keeper does the rest. Stumped!",
        "$$$$$ dances down but is deceived. The keeper is sharp. Stumped!",
        "$$$$$ charges out but is beaten by the turn. Stumped!",
        "$$$$$ goes for the big shot, and the keeper removes the bails. Stumped!",
        "$$$$$ is stranded out of his crease, and the keeper capitalizes. Stumped!",
        "$$$$$ is lured out of his crease and pays the price. Stumped!",
        "$$$$$ is out of his ground, and the keeper takes the bails off. Stumped!",
        "$$$$$ is beaten by the spin, and the keeper does the rest. Stumped!",
        "A sharp stumping by the keeper! $$$$$ is caught short. Stumped!"
    ],
    [
        "$$$$$ drives it straight back, and the bowler takes a sharp catch! Caught and bowled!",
        "A powerful shot, but $$$$$ finds the bowler's hands. Caught and bowled!",
        "$$$$$ mistimes the shot, and the bowler makes no mistake. Caught and bowled!",
        "$$$$$ hits it hard, and the bowler reacts quickly to pouch it. Caught and bowled!",
        "$$$$$ plays it back firmly, and the bowler holds on! Caught and bowled!",
        "$$$$$ smashes it straight, and the bowler grabs it! Caught and bowled!",
        "A full toss by the bowler, and $$$$$ hits it back. Caught and bowled!",
        "$$$$$ plays it gently, but the bowler dives forward to take the catch. Caught and bowled!",
        "$$$$$ attempts a drive, and the bowler takes a stunning return catch! Caught and bowled!",
        "$$$$$ hits it low, and the bowler gets down quickly to catch it! Caught and bowled!",
        "$$$$$ goes for the pull shot, but the bowler snatches it! Caught and bowled!",
        "$$$$$ tries to work it to the leg side, but the bowler hangs on! Caught and bowled!",
        "$$$$$ plays it straight, and the bowler catches it brilliantly! Caught and bowled!",
        "$$$$$ is beaten by the slower ball, and the bowler pouches it! Caught and bowled!",
        "$$$$$ hits it hard, and the bowler holds onto a tough chance! Caught and bowled!",
        "$$$$$ goes for the lofted shot, but the bowler takes a spectacular catch! Caught and bowled!",
        "$$$$$ drives it firmly, and the bowler catches it with one hand! Caught and bowled!",
        "$$$$$ attempts a drive, and the bowler clings on to it! Caught and bowled!",
        "$$$$$ tries to flick it, but the bowler dives and takes a stunning catch! Caught and bowled!",
        "$$$$$ hits it straight back, and the bowler completes the catch! Caught and bowled!"
    ],
    [
        "$$$$$ plays it down the leg side and accidentally knocks off the bails! Hit wicket!",
        "A big stride by $$$$$, but he dislodges the stumps while playing the shot! Hit wicket!",
        "$$$$$ goes deep in the crease and disturbs the stumps with his back foot! Hit wicket!",
        "$$$$$ tries to pull the ball, but his back leg hits the stumps! Hit wicket!",
        "$$$$$ attempts a big shot, but his bat hits the stumps! Hit wicket!",
        "$$$$$ plays it on the back foot and dislodges the bails! Hit wicket!",
        "$$$$$ goes for the cut shot, but his back foot clips the stumps! Hit wicket!",
        "$$$$$ steps back to defend, but he hits the stumps with his back leg! Hit wicket!",
        "$$$$$ moves across the crease and disturbs the stumps! Hit wicket!",
        "$$$$$ shuffles and clips the stumps with his back foot while playing the shot! Hit wicket!",
        "$$$$$ tries to sweep, but he hits the stumps with his back leg! Hit wicket!",
        "$$$$$ loses his balance while playing the shot and falls onto the stumps! Hit wicket!",
        "$$$$$ goes back in the crease and accidentally knocks off the bails! Hit wicket!",
        "$$$$$ attempts to play on the back foot but disturbs the stumps! Hit wicket!",
        "$$$$$ moves deep into the crease and hits the stumps with his back leg! Hit wicket!",
        "$$$$$ shuffles across and dislodges the stumps! Hit wicket!",
        "$$$$$ tries to pull, but his bat hits the stumps! Hit wicket!",
        "$$$$$ goes onto the back foot and clips the stumps! Hit wicket!",
        "$$$$$ attempts to cut, but his back leg hits the stumps! Hit wicket!",
        "$$$$$ moves across the crease and disturbs the stumps! Hit wicket!"
    ]
]
export { outVoice };