let battingAction = {
    hitBig: 0,
    defence: 1,
    normal: 2,
    superShot: 3,
    freeHit: 4
}
let bowlingAction = {
    tryForWicket: 0,
    saveRun: 1,
    normal: 2,
    specialBowl: 3,
}
export { bowlingAction, battingAction };
